function writeDebug(message:string, data?:object|null) { write('debug', message, data); }
function writeInfo(message:string, data?:object|null) { write('info', message, data); }
function writeWarn(message:string, data?:object|null) { write('warn', message, data); }
function writeErr(message:string, data?:object|null) { write('err', message, data); }

interface Map {
    [key: string]: any | undefined
  }

const lvlMap:Map = {
    'debug':4,
    'info':3,
    'warn':2,
    'err':1
};
const methodMap:Map = {
    'debug': console.log,
    'info': console.info,
    'warn': console.warn,
    'err': console.error
};

function write(level:string, message:string, data?:object|null){
    try {
        if (message === null || message === undefined || message === '') {
            if (isAllowed(level)) mapMethod(level)(new Date().toISOString().slice(0, -1) + ' - [' + level + ']', data);
        } else if (data === null || data === undefined) {
            if (isAllowed(level)) mapMethod(level)(new Date().toISOString().slice(0, -1) + ' - [' + level + '] - ' + message);
        } else {
            if (isAllowed(level)) mapMethod(level)(new Date().toISOString().slice(0, -1) + ' - [' + level + '] - ' + message, data);
        }

    } catch(e){}
}

function isAllowed(requested:string) {
    const l1 = lvlMap[localStorage.getItem('logLevel') as string] || 2;
    const l2 = lvlMap[requested] || 0;

    return l1 >= l2;
}

function mapMethod(requested:string) {
    return methodMap[requested] || function(){};
}

export { writeDebug, writeInfo, writeWarn, writeErr };
