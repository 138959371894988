import { Action, Reducer } from 'redux';
import { apiGet } from './apiWrapper'
import { writeDebug } from '../components/logger';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LocalizationState {
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SyncAction { type: 'SYNC_TRANSLATIONS' }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SyncAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    sync: (lang: string, addForLanguage: any, setAsActive: boolean) => async(dispatch: any, getState: any) => {
        const locData = localStorage.getItem('locales_data');
        const locLang = localStorage.getItem('locales_lang');
        const locVersion = localStorage.getItem('locales_version');

        if(locLang === lang) {
            if(locData !== null && locData !== undefined) {
                dispatch(addForLanguage(JSON.parse(locData), lang));
                apiGet(`/api/lang/${lang}`, {version:true}).then(res => {
                    if(res.data.version !== locVersion) {
                        writeDebug(`Local translations with version:[${locVersion}] is out-of-date loading fresh data`);
                        apiGet(`/api/lang/${lang}`, {version:true, data:true}).then(res => {
                            localStorage.setItem('locales_data', JSON.stringify(res.data.data));
                            localStorage.setItem('locales_version', res.data.version);
                            dispatch(addForLanguage(JSON.parse(res.data.data), lang));
                        })
                    } else {
                        writeDebug(`Using local translations with version:[${locVersion}]`)
                    }
                });
            } else {
                writeDebug(`Local translations not found loading`);
                apiGet(`/api/lang/${lang}`, {version:true, data:true}).then(res => {
                    localStorage.setItem('locales_data', JSON.stringify(res.data.data));
                    localStorage.setItem('locales_version', res.data.version);
                    dispatch(addForLanguage(res.data.data, lang));
                });
            }
        } else {
            writeDebug(`Local translations language does not match loading fresh data`);
            apiGet(`/api/lang/${lang}`, {version:true, data:true}).then(res => {
                localStorage.setItem('locales_data', JSON.stringify(res.data.data));
                localStorage.setItem('locales_version', res.data.version);
                dispatch(addForLanguage(res.data.data, lang));
            });
        }

        return ({ type: 'SYNC_TRANSLATIONS' } as SyncAction);
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<LocalizationState> = (state: LocalizationState | undefined, incomingAction: Action): LocalizationState => {
    if (state === undefined) {
        return { count: 0 };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        default:
            return state;
    }
};


