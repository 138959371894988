import axios, { AxiosRequestConfig } from 'axios';
import { writeDebug } from '../components/logger';

interface Map {
    [key: string]: string | undefined
  }

const langMap: Map = { ".sk": "sk-SK", ".cz": "cs-CZ", ".en": "en-US", ".de": "de-DE" };

const createHeaders = () => {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': getLanguage()
    };
};

function getLanguage():string {
    var r = (window.location.hostname.match(/(.cz$)|(.sk$)|(.en$)|(.de$)/gi) || ['.sk'])[0];
    return localStorage.locales_lang || langMap[r] || "sk-SK";
};

// GET | POST | PUT | DELETE must be raw functions to allow context propagation
const apiGet = function (url:string, params:object|null): Promise<any> {
    return apiFetch(url, params, null, 'GET',)
};
const apiPost = function (url:string, params:object|null, data:object|null): Promise<any> {
    return apiFetch(url, params, data, 'POST')
};
const apiPut = function (url:string, params:object|null, data:object|null): Promise<any> {
    return apiFetch(url, params, data, 'PUT')
};
const apiDelete = function (url:string, params:object|null, data:object|null): Promise<any> {
    return apiFetch(url, params, data, 'DELETE')
};

const apiFetch = function (url:string, params:object|null, data:object|null, method:string): Promise<any> {
    writeDebug("fetch - " + method + " - " + url);
    let config = { method: method, headers: createHeaders(), cache: "no-cache", url, paramsSerializer: createQs } as AxiosRequestConfig;
    if (data !== null && data !== undefined) {
        config.data = JSON.stringify(data);
    }

    config.params = Object.assign({}, params, { t:Date.now() });

    return axios.request(config);
};

const createQs = function (obj: any):string {
    const keys = Object.keys(obj);
    return keys.map(key => {
        const value = obj[key];

        if (value === undefined || value === null) {
            return '';
        }

        return encodeURI(key) + '=' + encodeURI(value);
    }).filter(x => x.length > 0).join('&');
}

export {
    apiGet, apiPost, apiPut, apiDelete
};