import React from 'react';

import './appLoadingScreen.scss';
import './root.scss'

export const AppLoadingScreen = () => {
    return (<>
        <div className='app-loading-screen'>
            <div className="bounce">
                <img alt="" className='cribis-logo' src="/img/crif.png"></img>
            </div>
        </div>
    </>)
};